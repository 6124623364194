import Admin from '@/models/bread/Admin/account'
import Moderator from '@/models/bread/Moderator/account'
import Teacher from '@/models/bread/Moderator/account'

export default {
  key:'Account',
  name:{
    singular:'Account',
    plural:'Accounts',
  },
  parents:[
    //
  ],
  Admin:Admin,
  Moderator:Moderator,
  Teacher:Teacher,
}