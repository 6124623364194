import datasetRole from '@/datasets/role'

export default {
  browse:{
    table:{
      headers: [
        {
          text: 'No.',
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Mobile',
          value: 'mobile',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Role',
          value: 'role',
        },
      ],
    },
  },
  read:{
    tabs:[
      {
        key:'Info',
        label:'info',
      },
    ],
    table:{
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Mobile',
          value: 'mobile',
        },
        {
          text: 'Role',
          value: 'role',
        },
        {
          text: 'Created',
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text: 'Last Updated',
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'mobile',
        name:'Mobile',
      },
      {
        type:'string',
        key:'name',
        name:'Name',
      },
      {
        type:'select',
        key:'role',
        name:'Role',
        options:datasetRole,
        optionText:'name',
        optionValue:'value',
        defaultValue:'admin',
      },
      {
        type:'password',
        key:'password',
        name:'Password',
      },
    ],
    rules:{
      mobile: [v => !!v || 'Mobile is required'],
      name: [v => !!v || 'Name is required'],
      role: [v => !!v || 'Role is required'],
      password: [v => !!v || 'Password is required'],
    },
  },
  edit:true,
  delete:true,
}