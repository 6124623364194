import Moderator from '@/models/bread/Moderator/user'

export default {
  key:'User',
  name:{
    singular:'User',
    plural:'Users',
  },
  parents:[
    'Account',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}