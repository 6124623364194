import i18n from '@/plugins/i18n'
export default {
  browse: {
    table: {
      headers: [{
          text: i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
        },
        {
          text: 'Owner',
          value: 'Owner.User.name',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: i18n.t("model.prop.mobile"),
          value: 'mobile',
        },
        {
          text: 'Telegram',
          value: 'telegramUid',
        },
        // {
        //   text: '',
        //   value: 'accountSelect',
        // },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
      {
        key: 'Group',
        label:  'Groups',
      },
      {
        key: 'User',
        label:  'Users',
      },
      {
        key: 'AccountInstitution',
        label:  'Institutions',
      },
      {
        key: 'Classroom',
        label:  'Classrooms',
      },
    ],
    table: {
      headers: [
        {
          text: 'Owner Name',
          value: 'Owner.User.name',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: i18n.t("model.prop.mobile"),
          value: 'mobile',
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
        {
          text: "Last invitation sent",
          value: "timestampInvited",
          filterable: false,
        },
      ],
    },
  },
  add: false,
  edit: true,
  delete: true,
}