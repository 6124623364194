export default [
  {
    id: 1,
    name: "Adult",
    value: "adult",
  },
  {
    id: 2,
    name: "Child",
    value: "child",
  },
]