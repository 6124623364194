export default [
  {
    id:1,
    name:'Admin',
    value:'admin',
  },
  {
    id:2,
    name:'Moderator',
    value:'moderator',
  },
  {
    id:3,
    name:'Teacher',
    value:'teacher',
  },
]