
import datasetSchoolYear from '@/datasets/schoolYear'
import datasetMonth from '@/datasets/month'
import datasetYear from '@/datasets/year'
import datasetUserType from '@/datasets/userType'
import i18n from '@/plugins/i18n'
import moment from 'moment/moment'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text: i18n.t("model.prop.mobile"),
          value: "Account.mobile",
        },
        {
          text:  'Type',
          value: 'type',
        },
      ],
    },
  },
  read:{
    tabs:[
      // {
      //   key:'Save',
      //   label:'saves',
      // },
      {
        key: 'Info',
        label:i18n.t("string.info"),
      },
      // {
      //   key:'Activity',
      //   label:i18n.t("model.name.activity"),
      // },
      // {
      //   key:'Performance',
      //   label:i18n.t("model.name.performance"),
      // },
    ],
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.name"),
          value: 'name',
        },
        {
          text:  'Type',
          value: 'type',
        },
        {
          text:  'School Year',
          value: 'schoolYear',
        },
        {
          text:  'Starting Year',
          value: 'year',
        },
        {
          text:  'Starting Month',
          value: 'month',
        },
        {
          text: 'Unverified Classroom Name',
          value: 'unverifiedClassroomName',
        },
        {
          text: 'Unverified Institution Name',
          value: 'unverifiedInstitutionName',
        },
        {
          text: 'Unverified Institution Address',
          value: 'unverifiedInstitutionAddress',
        },
        {
          text: 'Unverified Town Name',
          value: 'unverifiedTownName',
        },
        {
          text:  i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'string',
        key: 'name',
        name: i18n.t("model.prop.name"),
      },
      {
        type: 'select',
        key: 'type',
        name: 'User Type',
        options: datasetUserType,
        optionText: 'name',
        optionValue: 'value',
        defaultValue: 'child',
      },
      {
        type: 'select',
        key: 'schoolYear',
        name: i18n.t("model.prop.schoolYear"),
        options: datasetSchoolYear,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 1,
      },

      {
        type: 'select',
        key: 'month',
        name: 'Month',
        options: datasetMonth,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 3,
      },
      {
        type: 'select',
        key: 'year',
        name: 'Year',
        options: datasetYear,
        optionText: '',
        optionValue: '',
        defaultValue: parseInt(moment().format('YYYY')),
      },
    ],
    rules: {
      name: [v => !!v || i18n.t("rules.name")],
      year: [v => !!v || 'Year is required'],
      month: [v => !!v || 'Month is required'],
      schoolYear: [v => !!v || 'School year is required'],
    },
  },
  edit:{
    fields: [
      {
        type: 'string',
        key: 'name',
        name: i18n.t("model.prop.name"),
      },
      {
        type: 'select',
        key: 'type',
        name: 'User Type',
        options: datasetUserType,
        optionText: 'name',
        optionValue: 'value',
        defaultValue: 'child',
      },
      {
        type: 'select',
        key: 'schoolYear',
        name: i18n.t("model.prop.schoolYear"),
        options: datasetSchoolYear,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 1,
      },
      {
        type: 'string',
        key: 'unverifiedClassroomName',
        name: 'Unverified Classroom Name',
      },
      {
        type: 'string',
        key: 'unverifiedInstitutionName',
        name: 'Unverified Institution Name',
      },
      {
        type: 'string',
        key: 'unverifiedInstitutionAddress',
        name: 'Unverified Institution Address',
      },
      {
        type: 'string',
        key: 'unverifiedTownName',
        name: 'Unverified Town Name',
      },
      {
        type: 'select',
        key: 'month',
        name: 'Month',
        options: datasetMonth,
        optionText: 'name',
        optionValue: 'id',
        defaultValue: 3,
      },
      {
        type: 'select',
        key: 'year',
        name: 'Year',
        options: datasetYear,
        optionText: '',
        optionValue: '',
        defaultValue: parseInt(moment().format('YYYY')),
      },
    ],
    rules: {
      name: [v => !!v || i18n.t("rules.name")],
      year: [v => !!v || 'Year is required'],
      month: [v => !!v || 'Month is required'],
      schoolYear: [v => !!v || 'School year is required'],
    },
  },
  delete:true,
}