export default [
  {
    id:1,
    name:'Year 1',
  },
  {
    id:2,
    name:'Year 2',
  },
  {
    id:3,
    name:'Year 3',
  },
  {
    id:4,
    name:'Year 4',
  },
  {
    id:5,
    name:'Year 5',
  },
  {
    id:6,
    name:'Year 6',
  },
  // {
  //   id:100,
  //   name:'Preschool',
  // },
  // {
  //   id:9999,
  //   name:'Teacher',
  // },
]